import { isRouteErrorResponse, Link, useRouteError } from "@remix-run/react";
import { Feedback } from "../feedback";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { Button } from "@mindstonehq/ui";

export declare class ErrorResponse {
  status: number;
  statusText: string;
  data: any;
  error?: Error;
  internal: boolean;
  constructor(
    status: number,
    statusText: string | undefined,
    data: any,
    internal?: boolean,
  );
}

export function GenericBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <>
      {isRouteErrorResponse(error) ? (
        <GenericCatchBoundary error={error} />
      ) : (
        <GenericErrorBoundary
          error={error instanceof Error ? error : new Error("Unknown Error")}
        />
      )}
    </>
  );
}

export function GenericErrorBoundary({ error }: { error: Error }) {
  if ((error?.message || "").toLowerCase().includes("not_found")) {
    return (
      <Feedback
        code={404}
        title={"Not found"}
        subtitle={"This page doesn't exists."}
      />
    );
  }
  if ((error?.message || "").toLowerCase().includes("unauthorized")) {
    return (
      <Feedback
        code={401}
        title={"Sorry, not logged in"}
        subtitle={"Log in to access this page."}
        cta={
          <Link to="/login">
            <Button className="min-w-[132px]" variant="secondary">
              Log in
            </Button>
          </Link>
        }
      />
    );
  }
  if ((error?.message || "").toLowerCase().includes("forbidden")) {
    return (
      <Feedback
        code={403}
        title={"Sorry, no access"}
        subtitle={
          "It looks like you don't have permission to access this. Make sure you are using the correct account."
        }
      />
    );
  }
  return (
    <Feedback
      code={500}
      title={"Something went wrong"}
      subtitle={
        "This request can't be handled at this time. Please try again later."
      }
    />
  );
}

export function GenericCatchBoundary({ error }: { error: { status: number } }) {
  if (error.status === 404) {
    return (
      <Feedback
        code={404}
        title={"Not found"}
        subtitle={"This page doesn't exists."}
      />
    );
  }

  if (error.status === 403) {
    return (
      <Feedback
        code={403}
        title={"Forbidden"}
        subtitle={`This resource is not accessible by anyone other than its owner.
          \nPlease ask them for access.`}
      />
    );
  }

  return (
    <Feedback
      code={error.status}
      title={"Something went wrong"}
      subtitle={
        "This request can't be handled at this time. Please try again later."
      }
    />
  );
}
